import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";

const initState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  accountId: "",
};

const assignableProductSlice = createSlice({
  name: " assignableProduct",
  initialState: initState,
  reducers: {
    setAccountId(state, action) {
      state.accountId = action.payload;
    },
    setAccountProduct(state, action) {
      state.data = action.payload.parentsProducts;
      state.total = action.payload.total;
    },
    editAccountProduct(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
    setAccountProducts(state, action) {
      state.data = action.payload.assignedProduct;
      state.total = action.payload.total;
    },
  },
});

export const assignableProductActions = assignableProductSlice.actions;
export default assignableProductSlice.reducer;
export const addAccountId = (id) => {
  return async (dispatch) => {
    dispatch(assignableProductActions.setAccountId(id));
  };
};
export const getParentProducts = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/product/parentProducts?${new URLSearchParams(data).toString()}`
    );
    dispatch(assignableProductActions.setAccountProduct(response.data));
  };
};
export const addAccountProduct = (data, navigate) => {
  return async (dispatch) => {
    const response = await DataService.post(`/account-product/`, data);
    if (response.status >= 200 && response.status < 300) {
      dispatch(assignableProductActions.setAccountProducts(response.data));
      SuccessToast("edited");
      navigate(-1);
    } else {
      WarningToast("An error has occur");
    }
  };
};
export const editParentProducts = (id, data, navigate) => {
  return async (dispatch) => {
    const response = await DataService.patch(`/product/${id}`, data);

    if (response.status >= 200 && response.status < 300) {
      dispatch(assignableProductActions.editAccountProduct(response.data));
      SuccessToast("edited");
      navigate(-1);
    } else {
      WarningToast("An error has occur");
    }
  };
};
