import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;

export const login = ({ email, password }) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post("/staff/signin", {
        email,
        password,
      });
      dispatch(authActions.setUser(response.data.data.user));
      Cookies.set("token", response.data.data.token);
    } catch (err) {
      if (err.response?.data.message)
        WarningToast(err.response.data.message);
      else WarningToast("somthing went wrong, try later");
    }
  };
};

export const authUser = () => {
  return async (dispatch) => {
    try {
      dispatch(authActions.setLoading(true));
      const response = await DataService.get("/staff/pass");
      dispatch(authActions.setUser(response.data.user));
      dispatch(authActions.setLoading(false));
    } catch (error) {
      dispatch(authActions.setLoading(false));
      console.log(error);
    }
  };
};

export const editAuthUser = (id, data, setIsLoading) => {
  return async (dispatch) => {
    try {
      if (data.files) {
        const images = await uploadFileS3([...data.files], setIsLoading);
        data.avatar = images[0];
      }
      delete data.files;
      const response = await DataService.patch(`/staff/${id}`, data);
      SuccessToast("User Data Has Been Changed Please Refresh The page");
    } catch (error) {
      // console.log(error.response.data.message);
      WarningToast(error.response.data.message);
    }
  };
};
export const changePassword = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.patch(`/staff/change-password`, data);
      Cookies.remove("token");
      dispatch(authActions.setUser(null));
    } catch (error) {
      // console.log(error.response.data.message);
      WarningToast(error.response.data.message);
    }
  };
};
