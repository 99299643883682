// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
// import auth from './authentication'
// import todo from "@src/views/apps/todo/store";
// import chat from "@src/views/apps/chat/store";
// import users from '@src/views/apps/user/store'
// import email from "@src/views/apps/email/store";
// import invoice from "@src/views/apps/invoice/store";
// import calendar from "@src/views/apps/calendar/store";
// import ecommerce from "@src/views/apps/ecommerce/store";
// import dataTables from "@src/views/tables/data-tables/store";
// import permissions from "@src/views/apps/roles-permissions/store";

// import Contacts from "./contact-us/reducers";
// import complaintReducer from "./complaint/complaintSlice";
// import constructionsReducer from "./constructions-update/constructionsSlice";
import contactReducer from "./contact-us/contactSlice";
// import uniteReducer from "./unit/uniteSlice";
// import serviceRequestSlice from "./repair/serviceRequestSlice";
import statisticsReducer from "./statistics/statisticsSlice";
// import unitCategoryReducer from "./unit-category/unitCategorySlice";
// import serviceReducer from "./services/serviceSlice";
// import checkReducer from "./check/checkSlice";
import usersReducer from "./users/usersSlice";
// import gateInvitationReducer from "./gate-invitation/gateInvitationSlice";
// import compoundReducer from "./compound/compoundSlice";
import categoryReducer from "./category/categorySlice";
import authReducer from "./authentication/authSlice";
// import jobMonitorReducer from "./job-monitor/jobMonitorSlice";
import productSlice from "./product/productSlice";
import orderSlice from "./order/orderSlice";
import customerSlice from "./customer/customerSlice";
import providerSlice from "./adapter/providerSlice";
import accountPrdouctSlice from "./accountsProducts/accountProductSlice";
import assignableProductSlice from "./assignable-products/assignableProductSlice";
import vouchereHistorySlice from "./voucherHistory/voucherHistrorySlice";
import currencySlice from "./currency/currencySlice";
const rootReducer = {
  // auth,
  voucherHistory: vouchereHistorySlice,
  contactUs: contactReducer,
  products: productSlice,
  statistics: statisticsReducer,
  assignableProduct: assignableProductSlice,
  accountPrdouct: accountPrdouctSlice,
  // constructions: constructionsReducer,
  // newComplaint: complaintReducer,
  auth: authReducer,
  // checks: checkReducer,
  // unite: uniteReducer,
  users: usersReducer,
  orders: orderSlice,
  customers: customerSlice,
  currencies: currencySlice,
  // gateInvitation: gateInvitationReducer,
  // services: serviceReducer,
  // compound: compoundReducer,
  categories: categoryReducer,
  // jobMonitor: jobMonitorReducer,
  // serviceRequest: serviceRequestSlice,
  // todo,
  // chat,
  // email,
  // users,
  navbar,
  layout,
  // invoice,
  // calendar,
  // ecommerce,
  // dataTables,
  // permissions,
};

export default rootReducer;
