import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { getStatistics } from "../statistics/statisticsSlice";

const contactSlice = createSlice({
  name: "contacts",
  initialState: {
    data: [],
    total: 0,
  },
  reducers: {
    setContacts(state, action) {
      state.data = action.payload.contacts;
      state.total = action.payload.total;
    },
    editContact(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});

export const contactActions = contactSlice.actions;
export default contactSlice.reducer;

export const getNewContacts = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/contact-us/all?${new URLSearchParams(data).toString()}`
      );
      if (response.status >= 200 && response.status < 300) {
        // console.log("response", response);
        dispatch(contactActions.setContacts(response.data));
      }
    } catch (e) {
      WarningToast("An error has occur", e);
    }
  };
};

export const editContact = (id, data,navigate) => {
  return async (dispatch) => {
    const response = await DataService.patch(`/contact-us/${id}`, data);
    if (response.status >= 200 && response.status < 300) {
      dispatch(contactActions.editContact(response.data));
      dispatch(getStatistics());
      SuccessToast("edited");
      navigate(-1);

    } else {
      WarningToast("An error has occur");
    }
  };
};
