import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { WarningToast } from "../../utility/toast";

const initialState = {
  data: {},
};

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    setStatus(state, action) {
      state.data = action.payload;
    },
  },
});

export const statisticsActions = statisticsSlice.actions;
export default statisticsSlice.reducer;

export const getStatistics = () => {
  return async (dispatch) => {
    const response = await DataService.get("/statistics");
    if (response.status < 300 && response.status >= 200)
      dispatch(statisticsActions.setStatus(response.data));
    else WarningToast("Error Happend !");
  };
};
