import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { navigate } from "react-router-dom";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  plainProducts: [],
  totalPlainProducts: 0,
  parentProducts: [],
  parentProduct: null,
  totalParentProducts: 0,
  selectedProduct: [],
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const productSlice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {
    //  Normal Products
    setProducts(state, action) {
      state.data = action.payload.products;
      state.total = action.payload.total;
    },
    editProduct(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
    // End Products

    // Plain Products
    setPlainProducts(state, action) {
      state.plainProducts = action.payload.products;
      state.totalPlainProducts = action.payload.total;
    },
    addPlainProduct(state, action) {
      state.plainProducts.push(action.payload);
    },
    // End Plain Products

    // Parent Products
    setParentProducts(state, action) {
      state.parentProducts = action.payload.parentsProducts;
      state.totalParentProducts = action.payload.total;
    },
    setParentProduct(state, action) {
      state.parentProduct = action.payload;
    },
    // End Plain Products

    // Selected Product
    addSelectedProduct(state, action) {
      const isExsisit = state.selectedProduct.some((item) => item._id === action.payload._id);
      if (!isExsisit)
        state.selectedProduct.push(action.payload);
      else
        WarningToast("Product already selected");
    },
    deleteSelectedProduct(state, action) {
      // console.log("action.payload.id", action);
      const index = state.selectedProduct.findIndex(
        (item) => item._id === action.payload
      );
      if (index !== -1) state.selectedProduct.splice(index, 1);
    },
    resetSelectProduct(state, action) {
      state.selectedProduct = [];
    },
    // End Selected Product
  },
});

export const productActions = productSlice.actions;
export default productSlice.reducer;

// Start selected products
export const selectedProduct = (data) => {
  return async (dispatch) => {
    dispatch(productActions.addSelectedProduct(data));
  };
};
export const deleteSelectedProduct = (id) => {
  return async (dispatch) => {
    dispatch(productActions.deleteSelectedProduct(id));
  };
};

export const resetSelectProduct = () => {
  return async (dispatch) => {
    dispatch(productActions.resetSelectProduct());
  };
}
//End selected products

// Start Normal Products
export const getProducts = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/product/?${new URLSearchParams(data).toString()}`
    );
    dispatch(productActions.setProducts(response.data));
  };
};

export const editProduct = (id, data, setIsLoading, navigate) => {
  return async (dispatch) => {
    setIsLoading({ loading: true, progress: 0 });
    if (data.files) {
      const images = await uploadFileS3([...data.files], setIsLoading);
      data.image = images[0];
    }
    delete data.files;

    const response = await DataService.patch(`/product/${id}`, data);
    if (response.status >= 200 && response.status < 300) {
      dispatch(productActions.editProduct(response.data));
      setIsLoading({ loading: false, progress: 0 });
      SuccessToast("edited");
      navigate("/apps/parent-products");
    } else {
      WarningToast("An error has occur");
      setIsLoading({ loading: false, progress: 0 });
    }
  };
};

export const addProduct = (productData, setIsLoading, navigate) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });
      if (productData.files) {
        const images = await uploadFileS3([...productData.files], setIsLoading);
        productData.image = images[0];
      }
      delete productData.files;
      // console.log("productData", productData);

      const response = await DataService.post("/product", productData);
      dispatch(getProducts(pagin));
      setIsLoading({ loading: false, progress: 0 });
      SuccessToast("Added Successfully");
      dispatch(resetSelectProduct());
      navigate("/apps/products");
    } catch (err) {
      console.log(err);
      WarningToast("An error has occurr");
      setIsLoading({ loading: false, progress: 0 });
    }
  };
};

export const deleteProduct = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/product/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getProducts(pagin));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};
// End Normal Products

// Start Plain Products
export const getPlainProducts = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/product/plainProducts?${new URLSearchParams(data).toString()}`
    );
    // console.log("Plain Product data ", response.data);
    dispatch(productActions.setPlainProducts(response.data));
  };
};
export const addPlainProduct = (data) => {
  return async (dispatch) => {
    dispatch(productActions.addPlainProduct(data));
  };
}
// End Plain Products

// Start Parent Products
export const getParentProducts = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/product/parentProducts?${new URLSearchParams(data).toString()}`
    );
    // console.log("setParentProducts data ", response.data);
    dispatch(productActions.setParentProducts(response.data));
  };
};
export const getOneParentProduct = (id) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/product/parentProduct/${id}`
    );
    // console.log("setParentProducts data ", response.data);
    dispatch(productActions.setParentProduct(response.data));
  };
};
// End Parent Products

