import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { navigate } from "react-router-dom";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const providerSlice = createSlice({
  name: "providerSlice",
  initialState,
  reducers: {
    setProvider(state, action) {
      state.data = action.payload.provider;
      state.total = action.payload.total;
    },
  },
});

export const providerActions = providerSlice.actions;
export default providerSlice.reducer;

export const getProvider = () => {
  return async (dispatch) => {
    const response = await DataService.get(`/adapter`);
    dispatch(providerActions.setProvider(await response.data));
  };
};
