import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  selectedOrder: null,
  totalSpentByCustomer: null,
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrders(state, action) {
      state.data = action.payload.orders;
      state.total = action.payload.total;
    },
    // setLoadingStart(state, action) {
    //   state.selectedOrder = null;
    //   state.loading = action.payload;
    // },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    getOrderDetails(state, action) {
      state.selectedOrder = action.payload;
    },
    setTotalSpent(state, action) {
      state.totalSpentByCustomer = action.payload;
    }

  },
});

export const orderActions = orderSlice.actions;
export default orderSlice.reducer;

export const getOrders = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/order?${new URLSearchParams(data).toString()}`
    );
    dispatch(orderActions.setOrders(response.data));
  };
};
export const getOrderDetails = (id) => {
  return async (dispatch) => {
    // dispatch(orderActions.setLoadingStart(true));
    const response = await DataService.get(`/order/${id}`);
    dispatch(orderActions.getOrderDetails(response.data));

  };
}
export const getTotalSpentByCustomer = (id) => {
  return async (dispatch) => {
    // dispatch(orderActions.setLoadingStart(true));
    const response = await DataService.get(`/order/customer/${id}/stats`);
    dispatch(orderActions.setTotalSpent(response.data));

  };
}