import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  selectedCustomer: null,
  coinsTransactions: [],
  pointsTransactions: [],
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers(state, action) {
      state.data = action.payload.customers;
      state.total = action.payload.total;
    },
    setLoadingStart(state, action) {
      state.selectedCustomer = null;
      state.loading = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    getCustomerDetails(state, action) {
      state.selectedCustomer = action.payload;
    },
    setPointsTransactions(state, action) {
      state.pointsTransactions = action.payload;
    },
    setCoinsTransactions(state, action) {
      state.coinsTransactions = action.payload;
    }

  },
});

export const customerActions = customerSlice.actions;
export default customerSlice.reducer;

export const getCustomers = (data) => {
  return async (dispatch) => {
    // dispatch(customerActions.setLoadingStart(true));
    const response = await DataService.get(
      `/customer?${new URLSearchParams(data).toString()}`
    );
    dispatch(customerActions.setCustomers(response.data));
    // dispatch(customerActions.setLoadingStart(false));
  };
};
export const getCustomerDetails = (id) => {
  return async (dispatch) => {
    // dispatch(customerActions.setLoadingStart(true));
    const response = await DataService.get(`/customer/${id}`);
    dispatch(customerActions.getCustomerDetails(response.data));
    // dispatch(customerActions.setLoadingStart(false));

  };
};
export const getPointsTransactions = (id) => {
  return async (dispatch) => {
    // dispatch(customerActions.setLoadingStart(true));
    const response = await DataService.get(`/points/getPoints/${id}`);
    dispatch(customerActions.setPointsTransactions(response.data));
    // dispatch(customerActions.setLoadingStart(false));

  };
};
export const getCoinsTransactions = (id) => {
  return async (dispatch) => {
    // dispatch(customerActions.setLoadingStart(true));
    const response = await DataService.get(`/coins/getCoins/${id}`);
    dispatch(customerActions.setCoinsTransactions(response.data));
    // dispatch(customerActions.setLoadingStart(false));

  };
};
export const addCoins = ({ customer, amount }) => {
  return async (dispatch) => {
    // dispatch(customerActions.setLoadingStart(true));
    try {
      const response = await DataService.post(`/coins/addCoins`, { customer, amount });
      SuccssToast("Coins added successfully");
    } catch (err) {
      WarningToast(err.response.data.message);
    }
    dispatch(getCustomerDetails(customer));
    dispatch(getCoinsTransactions(customer));
    // dispatch(customerActions.setLoadingStart(false));

  };
};
