import toast from 'react-hot-toast'

const Toast = () => {
  toast.dark('🦄 Wrong email or password!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  })
}
const WarningToast = (msg) => {
  toast.error(`${msg}`, {
    position: "top-center",
    icon: '❌'
  })
}
const SuccessToast = (msg) => {
  toast.success(`${msg}`, {
    position: "top-center",
    icon: '✅'
  })
}
//export toast as default and warning toast
export { Toast, WarningToast, SuccessToast }