import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";

const initState = {
  loading: false,
  error: null,
  data: [],

  total: 0,
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};
const vouchereHistorySlice = createSlice({
  name: "voucherHistory",
  initialState: initState,
  reducers: {
    setVouchersHistory(state, action) {
      console.log("VouchersHistory====>", action.payload.data.reservation);
      state.data = action.payload.data.reservation;

      state.total = action.payload.data.total;
    },
    editVouchersHistory(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});

export const vouchersHistoryActions = vouchereHistorySlice.actions;
export default vouchereHistorySlice.reducer;

export const getVouchereHistory = (id, data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/purchases/resrvationHistAccount/${id}?${new URLSearchParams(
        data
      ).toString()}`
    );
    console.log("res---->", response.data);
    dispatch(vouchersHistoryActions.setVouchersHistory(response.data));
  };
};
