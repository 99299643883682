import axios from "axios";
import Cookies from "js-cookie";
import { SuccessToast, WarningToast } from "../../utility/toast";

// const API_ENDPOINT = "http://localhost:3010"; //process.env.REACT_APP_API_ENDPOINT;
// const API_ENDPOINT = "https://vhub.smartlink.lol"; //process.env.REACT_APP_API_ENDPOINT;
// const API_ENDPOINT = "https://vhub-api.codpark.com/"; //process.env.REACT_APP_API_ENDPOINT;
const API_ENDPOINT = "https://vhub-api.sl1.live/"; //process.env.REACT_APP_API_ENDPOINT;
// const API_ENDPOINT = "http://142.93.169.71:3010/"; //process.env.REACT_APP_API_ENDPOINT;

const authHeader = () => ({
  Authorization: `Bearer ${Cookies.get("token")}`,
});
const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${Cookies.get("token")}`,
    entity: "Vhub",
    "Content-Type": "application/json",
  },
});

class DataService {
  static get(path = "") {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }
  static delete(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "DELETE",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = "", data = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
  static putFormData(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${Cookies.get("token")}`,
  };

  return requestConfig;
});

const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

export const upload = (ExcelSheet, baseurl, getterFunction, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });
      const response = await axios.post(
        `${API_ENDPOINT}${baseurl}`,
        ExcelSheet,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            entity: "vhub",
          },
          onUploadProgress: (e) => {
            setIsLoading({
              loading: true,
              progress: Math.trunc((e.loaded / e.total) * 100),
              uploaded: false,
            });
          },
        }
      );
      SuccessToast("Upload success");
      dispatch(getterFunction(pagin));
      setIsLoading({ loading: false, progress: 0, uploaded: true });
    } catch (err) {
      WarningToast(err.response.data.message);
      // console.log(err);
      setIsLoading({ loading: false, progress: 0 });
    }
  };
};

export { DataService };
