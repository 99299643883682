import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";

const initialState = {
  loading: false,
  error: null,
  accounts: [],
  staff: [],
  totalAccount: 0,
  totalStaff: 0,
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const usersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    setUsers(state, action) {
      state.accounts = action.payload.account;
      state.totalAccount = action.payload.total;
    },
    setUser(state, action) {
      // console.log("action.payload user", action.payload);
      state.accounts = [action.payload];
    },
    setStaff(state, action) {
      state.staff = action.payload.user;
      state.totalStaff = action.payload.total;
    },
    addUser(state, action) {
      state.accounts.unshift(action.payload);
    },
    addStaff(state, action) {
      state.staff.unshift(action.payload);
    },
    editUser(state, action) {
      console.log("action.payload", action.payload);
      const index = state.accounts.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.accounts.push(action.payload);
      else state.accounts[index] = action.payload;
    },
    editStaff(state, action) {
      const index = state.staff.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.staff.push(action.payload);
      else state.staff[index] = action.payload;
    },
  },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;

export const getUsers = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/account/all?${new URLSearchParams(data).toString()}`
    );
    // console.log(response);
    if (response.status >= 200 && response.status < 300) {
      dispatch(usersActions.setUsers(response.data));
    } else {
      WarningToast("An error has occur");
    }
  };
};

export const getUser = (id) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/account/${id}`
    );
    // console.log("response get user", response);
    if (response.status >= 200 && response.status < 300) {
      console.log(response.data);
      dispatch(usersActions.setUser(response.data));
    } else {
      WarningToast("An error has occur");
    }
  };
}

export const addNewUser = (user, navigate) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(`/account`, user);
      dispatch(usersActions.addUser(response.data));
      SuccessToast("User has been added");
      navigate("/apps/account");
    }
    catch (e) {
      WarningToast(e.response.data.message, 10);
    }
  };
};

export const editUser = (id, data, navigate) => {
  return async (dispatch) => {
    const response = await DataService.patch(`/account/${id}`, data);
    if (response.status >= 200 && response.status < 300) {
      console.log("response edit user", response.data);
      dispatch(usersActions.editUser(response.data));
      // dispatch(usersActions.editUser(response.data.data));
      SuccessToast("edited");
      navigate(-1);
    } else {
      WarningToast("An error has occur");
    }
  };
};

export const getStaff = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/staff/all?${new URLSearchParams(data).toString()}`
    );
    if (response.status >= 200 && response.status < 300) {
      dispatch(usersActions.setStaff(response.data.data));
    } else {
      WarningToast("An error has occur");
    }
  };
};

export const addNewStaff = (user, navigate) => {
  return async (dispatch) => {
    try {
      // console.log("user", user);
      const response = await DataService.post(`/staff`, user);
      if (response.status >= 200 && response.status < 300) {
        dispatch(usersActions.addStaff(response.data.data));
        SuccessToast("User has been added");
        navigate("/apps/staff");
      } else WarningToast("An error has occur");
    } catch (e) {
      WarningToast(e.response.data.message, 10);
    }
  };
};

export const editStaff = (id, data, navigate) => {
  return async (dispatch) => {
    const response = await DataService.patch(`/staff/${id}`, data);
    if (response.status >= 200 && response.status < 300) {
      dispatch(usersActions.editStaff(response.data.data));
      SuccessToast("edited");
      navigate(-1);
    } else {
      WarningToast("An error has occur");
    }
  };
};
export const deleteStaff = (id) => {
  return async (dispatch) => {
    const response = await DataService.delete(`/user/staff/${id}`);
    // console.log(response);
    if (response.status >= 200 && response.status < 300) {
      dispatch(getStaff(pagin));
      SuccessToast("Deleted");
    } else {
      WarningToast("An error has occur");
    }
  };
};
