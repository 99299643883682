import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  selectedCustomer: null,
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const currencySlice = createSlice({
  name: "currencies",
  initialState,
  reducers: {
    setCurrencies(state, action) {
      state.data = action.payload.rates;
      state.total = action.payload.total;
    },
    setLoadingStart(state, action) {
      state.selectedCustomer = null;
      state.loading = action.payload;
    },
    getCustomerDetails(state, action) {
      state.selectedCustomer = action.payload;
    }

  },
});

export const currencyActions = currencySlice.actions;
export default currencySlice.reducer;

export const getExchangeRates = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/currency?${new URLSearchParams(data).toString()}`
    );
    dispatch(currencyActions.setCurrencies(response.data));
  };
};
// export const getCustomerDetails = (id) => {
//   return async (dispatch) => {
//     dispatch(currencyActions.setLoadingStart(true));
//     const response = await DataService.get(`/currency/${id}`);
//     dispatch(currencyActions.getCustomerDetails(response.data));
//   };
// }