import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initState = {
  loading: false,
  error: null,
  data: [],
  parentProducts: [],
  totalParentProducts: 0,
  total: 0,
  accountId: "",
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};
const accountProductSlice = createSlice({
  name: "accountProduct",
  initialState: initState,
  reducers: {
    setAccountId(state, action) {
      state.accountId = action.payload;
    },
    setParentProductByAccount(state, action) {
      state.parentProducts = action.payload.parentsProducts;
      state.totalParentProducts = action.payload.total;
    },
    setAccountProduct(state, action) {
      state.data = action.payload.data.product;

      state.total = action.payload.data.total;
    },
    editAccountProduct(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
    togglePopulate(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) return;
      else state.data[index] = { ...state.data[index], featured: !state.data[index].featured };
    },
    toggleStock(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) return;
      else state.data[index] = { ...state.data[index], stock: !state.data[index].stock };
    },
    resetDate(state) {
      state.data = [];
      state.total = 0;
    },
  },
});

export const acountProductActions = accountProductSlice.actions;
export default accountProductSlice.reducer;

export const addAccountId = (id) => {
  return async (dispatch) => {
    dispatch(acountProductActions.setAccountId(id));
  };
};

export const resetDate = () => {
  return async (dispatch) => {
    dispatch(acountProductActions.resetDate());
  }
}


export const getProductsByAccount = (id, data) => {
  return async (dispatch) => {
    // get with query
    const response = await DataService.get(
      `/account-product/productsByAccount/${id}?${new URLSearchParams(data).toString()}`,
    );
    dispatch(acountProductActions.setAccountProduct(response.data));
  };
};

export const getAccountsProducts = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/account-product/products?${new URLSearchParams(data).toString()}`
    );
    dispatch(acountProductActions.setAccountProduct(response.data));
  };
};

export const editAccountProducts = (id, data, setIsLoading, navigate) => {
  return async (dispatch) => {
    setIsLoading({ loading: true, progress: 0 });
    if (data.files) {
      const images = await uploadFileS3([...data.files], setIsLoading);
      // console.log(images);
      data.image = images[0];
    }
    delete data.files;
    if (data.filesCover) {
      const covers = await uploadFileS3([...data.filesCover], setIsLoading);
      data.covers = covers[0];
    }
    delete data.filesCover;

    const response = await DataService.patch(`/account-product/${id}`, data);

    if (response.status >= 200 && response.status < 300) {
      dispatch(acountProductActions.editAccountProduct(response.data));
      SuccessToast("edited");
      navigate(-1);
    } else {
      WarningToast("An error has occur");
    }
  };
};

export const popularUpdate = (id, popular) => {
  return async (dispatch) => {
    const response = await DataService.patch(`/account-product/${id}`, { featured: !popular });
    if (response.status >= 200 && response.status < 300) {
      const accountId2 = response.data.data.account;
      // dispatch(getProductsByAccount(accountId2, pagin));
      dispatch(acountProductActions.togglePopulate({ _id: id }));
      SuccessToast(popular ? "Product isn't featured now" : "Product is featured now");
    } else {
      WarningToast("An error has occur");
    }
  };
}
export const stockUpdate = (id, stock) => {
  return async (dispatch) => {
    const response = await DataService.patch(`/account-product/${id}`, { stock: !stock });
    if (response.status >= 200 && response.status < 300) {
      const accountId2 = response.data.data.account;
      // dispatch(getProductsByAccount(accountId2, pagin));
      dispatch(acountProductActions.toggleStock({ _id: id }));
      SuccessToast(stock ? "Product isn't stock now" : "Product is stock now");
    } else {
      WarningToast("An error has occur");
    }
  };
}

export const addAccountProducts = (data, setIsLoading, navigate) => {
  return async (dispatch) => {
    setIsLoading({ loading: true, progress: 0 });
    if (data.files) {
      const images = await uploadFileS3([...data.files], setIsLoading);
      // console.log(images);
      data.image = images[0];
    }
    delete data.files;
    if (data.filesCover) {
      const covers = await uploadFileS3([...data.filesCover], setIsLoading);
      data.covers = covers[0];
    }
    delete data.filesCover;

    const response = await DataService.post(`/account-product`, data);

    if (response.status >= 200 && response.status < 300) {
      // dispatch(acountProductActions.setAccountProduct(response.data));
      SuccessToast("Added");
      navigate(-1);
    } else {
      WarningToast("Server Error");
    }
  };
};

export const deleteProductAccount = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/account-product/${id}`);
      if (response.status >= 200 && response.status < 300) {
        const accountId2 = response.data.data.account;
        dispatch(getProductsByAccount(accountId2, pagin));

        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};

export const getParentProductsByAccount = (id, data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/product/parentProductsByAccount/${id}?${new URLSearchParams(
        data
      ).toString()}`
    );
    dispatch(acountProductActions.setParentProductByAccount(response.data));
  };
};
