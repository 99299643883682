import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategory(state, action) {
      state.data = action.payload.categories;
      state.total = action.payload.total;
    },
    addCategory(state, action) {
      state.data.unshift(action.payload);
    },
    editCategory(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});

export const categoryActions = categorySlice.actions;
export default categorySlice.reducer;

export const getCategory = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/categories?${new URLSearchParams(data).toString()}`
    );
    dispatch(categoryActions.setCategory(response.data));
  };
};

export const editCategory = (id, data, setIsLoading, navigate) => {
  return async (dispatch) => {
    // console.log("data.files", data);
    if (data.files) {
      const images = await uploadFileS3([...data.files], setIsLoading);
      data.gameIcon = images[0];
    }
    delete data.files;

    const response = await DataService.patch(`/categories/${id}`, data);
    if (response.status >= 200 && response.status < 300) {
      dispatch(categoryActions.editCategory(response.data));
      SuccessToast("category has been updated");
      navigate("/apps/categories");
    } else {
      WarningToast("An error has occur");
    }
  };
};

// export const addCategory = (data, navigate, update, setIsLoading) => {
//   return async (dispatch) => {
//     try {
//       if (data.newcover) {
//         const uploadurls = await uploadFileS3(data.newcover, setIsLoading);
//         data.cover = uploadurls[0];
//         delete data.newcover;
//       }
//       let response;
//       if (update) {
//         response = await DataService.patch(`/categories/${data._id}`, data);
//         navigate("/apps/category");

//       }
//       else response = await DataService.post("/categories", data);
//       if (response.status >= 200 && response.status < 300) {
//         dispatch(getCategory(pagin));
//         SuccessToast("Added Successfully");
//         setIsLoading({ loading: false, progress: 0 });
//         navigate("/apps/category");

//       } else {
//         WarningToast("An error has occurr");
//         setIsLoading({ loading: false, progress: 0 });
//       }
//     } catch (err) {
//       WarningToast("An error has occurr");
//       setIsLoading({ loading: false, progress: 0 });
//     }
//   };
// };

export const addCategory = (category, setIsLoading, navigate) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });
      if (category.files) {
        const images = await uploadFileS3([...category.files], setIsLoading);
        // console.log(images);
        category.gameIcon = images[0];
      }
      delete category.files;

      const response = await DataService.post(`/categories`, category);
      dispatch(categoryActions.addCategory(response.data));
      SuccessToast("category has been added");
      setIsLoading({ loading: false, progress: 0 });
      navigate("/apps/categories");
    }
    catch (e) {
      WarningToast(e.response.data.message, 10);
      setIsLoading({ loading: false, progress: 0 });
    }
  };
};

export const deleteCategory = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/categories/${id}`);
      if (response.status >= 200 && response.status < 300) {
        // console.log(response.data);
        dispatch(getCategory(pagin));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};
